@import "styles/threeDots.css";

:root {
  --font-color-primary: #49596B;
  --font-color-menu: #7D8DD8;

  --border-radius: 8px;
  --bg-color: #f1f1f1;
  --font-white: #f1f1f1;
  --font-dark-color: #3d3d3d;
  --font-normal-color: #49596B;
  --font-disabled-color: #F1F1F1;
  --block-bg-color: #f9f9f9;
  --font-color-green: #40cdc9;
}
@font-face {
  font-family: Arkhip;
  src: url(./fonts/Arkhip-Regular.woff);
  font-display: swap
}
@font-face {
  font-family: Roboto;
  src: url(./fonts/Roboto-Medium.ttf);
  font-display: swap
}

@font-face {
  font-family: Roboto-Regular;
  src: url(./fonts/Roboto-Regular.ttf);
  font-display: swap
}
html, body{
  height: 100%;
}
body{
  background-color: var(--bg-color);
  color: var(--font-color-primary);
  min-height: 500px;
}
h3 {
  font: normal normal normal 20px/26px Arkhip;
}

button, input, select, .button,
div.c-select__placeholder,
.metamask-info {
  font: normal normal normal 16px/24px Roboto-regular;
}

body,
nav ul li[aria-disabled="true"] a, .menu-footer a,
.menu-footer a
{
    font: normal normal normal 16px/26px Roboto-Regular;
}

.block-title,
table thead th, span,
label
{
    font: normal normal normal 16px/26px Arkhip;
}

nav ul li a, .menu-footer a,
table tbody td,
.offset-detail > div > div
{
    font: normal normal normal 16px/26px Roboto;
}

.roboto-regular-16-26 {
  font: normal normal normal 16px/26px Roboto-Regular !important;
}

.primary-color {
  color: var(--font-color-primary) !important;
}

#root{
  padding: 30px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
}

.root-container{
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
}

.menu-nav{
  flex-grow: 1;
}

nav{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 270px;
  padding: 25px 0px 25px 30px;
  background: transparent linear-gradient(344deg, #D83AEB 0%, #37CCC8 100%) 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 3px 6px #49596B33; */
  display: flex;
  flex-direction: column;
  flex-flow: column;
  border-radius: var(--border-radius);
  opacity: 1;
  height: calc(100% - 50px);
  transition: width 0.7s;
}

nav a {
  height: 26px;
}

.navWrapper {
  position: fixed;
  height: calc(100% - 60px);
  min-height: 600px;
  top: 0;
  z-index: 1;
  margin: 30px 0 30px 0;
}

nav ul {
  padding: 0;
  list-style-type: none;
}
nav ul li a, .menu-footer a {
  color: var(--font-white);
  padding: 12px 0px 12px 70px ;
  margin: 30px 0;
  position: relative;
}
nav ul li[aria-disabled="true"] a{
  color: var(--font-disabled-color);
  cursor: default;
  opacity: 0.4;
}

nav ul li a, nav ul li a::selection{
  display: block;
  text-decoration: none;
}

nav ul li a.selected {
  background-color: var(--bg-color);
  border-radius: 25px 0 0 25px;
  color: var(--font-color-menu);
  position: relative;
  height: 27px;
  opacity: 1;
}

nav ul li a.selected:after,
nav ul li a.selected:before {
    content: " ";
    position: absolute;
    width: 10px;
    height: 10px;
}

nav ul li a.selected:after {
  right: 0;
  top: -10px;
  box-shadow: 0 5px 0 0 var(--bg-color);
  border-radius: 0 0 5px 0;
}

nav ul li a.selected:before {
  right: 0px;
  bottom: -10px;
  box-shadow: 0 -5px 0 0 var(--bg-color);
  border-radius: 0 5px 0 0;
}

.menu-footer{
  padding-bottom: 0;
  margin-bottom: 0;
  cursor: pointer;
  flex-grow: 1;
  position: absolute;
  bottom: -10px;
}

.menu-footer a {
  color: var(--font-white);
  opacity: 0.8;
}

.footer-menu-item {
  display: block;
  text-decoration: none;
  color: var(--font-white);
  padding: 12px 0px 12px 70px;
  position: relative;
  font: normal normal normal 16px/26px Roboto;
}
button.footer-menu-item {
  background-color: #00000000;
  width: auto;
  height: auto;
  box-shadow: none;
  text-transform: none !important;
}

.menu-item-icon{
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--bg-color); */
  width: 50px;
  height: 50px;
  /* border-radius: 50%; */
}
.menu-logo{
  opacity: 1;
  margin-bottom: 60px;
}
.menu-logo img {
  /* width: 149px; */
  height: 50px;
}
.content-container{
  position: relative;
  left: 295px;
  margin-left: 50px;
  margin-right: 70px;
  width: calc(100vw - 475px);

  /* margin: auto 100px; */
  padding-bottom: 20px;
  /* margin-left: 390px; */
  transition: width 0.8s;
  transition: left 0.8s;
}

.content-container-modal{
  max-width: 900px;
}
.error-message-container{
  min-width: 600px;
}
.content-container-inner, .content-container-modal, .error-message-container{
  padding: 20px 30px;
  border-radius: var(--border-radius);
  background-color: var(--block-bg-color);
  box-shadow: 0px 3px 8px #49596B33;
  height: auto;
  /* width: calc(100vw - 600px); */
}
.content-container-inner{
  margin-bottom: 30px;
}

.content-container-inner-label{
  padding: 0 0 15px 0;
}

.no-top-padding{
  padding-top: 0;
}

button, input, select, .button{
  cursor: pointer;
  width: 230px;
  height: 50px;
  box-shadow: 0 3px 6px rgb(61 57 90 / 15%);
  border: 2px solid #fff;
  background-color: #fff;
  border-radius: 8px;
  transition: .3s;
  border: none;
  color: var(--font-normal-color);
}
button, .button{
  font: normal normal normal 18px/26px Arkhip;
  letter-spacing: 0px;
}
.button-tsm{
  font-size: 15px;
}
button:disabled, .button:disabled {
  cursor: not-allowed;
  background: #C7CCD1 0% 0% no-repeat padding-box;
  position: relative;
  opacity: 0.8;
}
button:disabled::after, a[aria-disabled=true]::after, .button:disabled::after, .react-tabs__tab--disabled:after{
  position: absolute;
  right: 10px;
  width: 13px;
  height: 16px;
  top: calc(50% - 8px);
  content: ' ';
  background-image: url('./images/icon-lock.png');
}
button.selectable:not(:disabled):hover, .button.selectable:not(:disabled):hover, button.selectable.selected{
  background: transparent linear-gradient(344deg, #D83AEB 0%, #37CCC8 100%) 0% 0% no-repeat padding-box;
  color: var(--font-white);
}
a[aria-disabled=true]::after{
  right: auto;
  margin-left: 20px;
}
input:not(.react-select-3-input) {
  padding: 0 15px;
  cursor: default;
  box-sizing: border-box;
  width: 100%;
  color: #9A9A9A;
}
input:focus{
  border-color: none;
  outline: none;
}
label{
  display: block;
  color: #49596B;
}
button, label {
  text-transform: lowercase;
}

.wallet-container{
  display: flex;
  /* align-items:center; */
  flex-direction: column;
}
.wallet-container > p {
  margin-top:0;
}
.wallet-container > * {
  flex: 1 1 auto;
}
.button-container, .tab-button-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;
}
.content-container-inner .button-container:last-child{
  margin-bottom: 0;
}
.tab-button-container{
  margin-bottom: 15px;
}
.button-container-veritcal{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;
}
.button-container button{
  font: normal normal normal 16px/20px Arkhip;
  padding: 10px;
  height: 65px;
}
.tab-button-container button {
  font: normal normal normal 14px/24px Roboto;
}
.wallet-container .button-container > div:first-child, .wallet-container .button-container > div:nth-child(3n) {
  text-align: right;
}
.wallet-container .button-container > div {
  width: 48%;
}
div.c-select__control{
  border: none;
  border-radius: var(--border-radius);
  box-shadow: 0 3px 6px rgb(61 57 90 / 15%);

}
div.c-select__control input {
  height: 46px;
}
span.c-select__indicator-separator {
  display: none;
}

.metamask-info{
  text-align: center;
}
.block-title{
  text-transform: lowercase;
}
.block-title img {
  margin-bottom: -10px;
}
table {
  width: 100%;
}

table thead th {
  padding: 10px 0;
  border-bottom: 2px solid #fff;
  text-align: left;
}
table tbody td {
  padding: 10px 0;
}
table tbody td a {
  text-decoration: none;
}
table tbody td a:hover {
  opacity: 0.7;
}

.text-center{
  text-align: center;
}

.greeting-container{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.greeting-buttons{
  margin-top: 30px;
}
.greeting-buttons button{
  margin: 0 30px;
}
.offset-detail > div {
  display: flex;
}
.offset-detail > div:not(:last-child){
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.offset-detail > div > label {
  width: 35%;
  padding: 0;
}
.content-container-inner.closable{
  position: relative;
}
.content-container-inner.closable > .close-button, .cert-detail .close-button{
  position: absolute;
  right: -25px;
  top: -25px;
  margin: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgb(61 57 90 / 15%);
  background: #fff url('./images/icon-close.png') 50% 50% no-repeat padding-box;
  cursor: pointer;
}
.cert-detail .close-button{
  position: initial;
}
.cert-detail{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  background-color: transparent;
  flex-direction: column;
  width: 960px;
}
.cert-detail > img{
  border-radius: 8px;
  background-color: #fff;
  padding: 30px
}
.actions-container{
  margin-top: 50px;
  display: flex;
  width: 100%;
}
.actions-container > div {
  flex: 1 1 33.3%;
}
.prev:disabled::after, .next:disabled::after{
  width: 0;
  height: 0;
}
.prev, .next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 30px;
}
.prev span, .next span{
  font-size: 24px;
  margin-left: -2px;
  margin-top: -2px;
  opacity: 0.8;
}
.next span{
  margin-left: 3px;
}
.actions-container .print{
  display: flex;
  justify-content: right;
}

.button-b{
  width: 320px;
}

.dapp-container{
  min-height: calc(100vh - 60px);
  padding-bottom: 30px;
}
.btn-icon{
  margin-right: 10px;
}
.color-w{
  color: #9D6FDF;
}
.color-b{
  color: #6FA1D5;
}
.color-g{
  color: #37CCC8;
}
.p-tb{
padding: 10px 0 10px 0;
}
.p-b{
  padding-bottom: 10px;
}
.react-tiny-popover-container{
  background-color: #49596B;
  padding: 10px 15px;
  font-size: 12px;
  line-height: 14px;
  color: var(--font-white);
  max-width: 250px;
  /* margin-top: 40px; */
}
.info-icon{
  padding: 0 5px ;
  transition: opacity 0.6s linear;
}

.input-icons img {
  position: absolute;
}

.input-icons {
  width: 100%;
}

.icon {
  padding: 15px;
  min-width: 20px;
}

.search {
  padding-left: 55px !important;
}

span:not(.text-default), a {
  font: normal normal normal 14px/24px Roboto-Regular;
  color: #695F8A;
}

.invalid {
  border: 1px solid red;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 3px 8px #49596B33;
  border-radius: 8px;
  background: #F9F9F9 0% 0% no-repeat padding-box;
  z-index: 100001 !important;
}

.modalPopover {
  z-index: 100002 !important;
}

.modalBackground {
  background: #49596b78 0% 0% no-repeat padding-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000 !important;
}

.modalButton {
  margin-top: 20px;
}
.modalTitle {
  padding-bottom: 20px;
}

/* Modal close button */
.close {
  width: 50px;
  height: 50px;
  box-shadow: 0px 3px 6px #49596B26;
  position: absolute;
  top: -25px;
  right: -25px;
  border-radius: 50%;
}

.horizontalContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.horizontalContainer > *:not(:last-child) {
  margin-right: 25px;
  flex-shrink: 0;
}

.horizontalContainer > *:last-child {
  width: 100%;
}

.expandButton, .collapseButton {
  width: 10px !important;;
  height: 10px;
  float: right;
  position: relative;
  padding: 10px;
}

.expandButton {
  transform: rotate(180deg);
}

.noPadding {
  padding: 0 !important;
}

.greenHoverBorder:hover{
  border: 2px solid #37CCC8;
}
.greenHoverBorder{
  font-size: 16px;
}

.purpleHoverBorder:hover{
  border: 2px solid #9d6fdf;
}

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wordWrap {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: anywhere;
  word-break: break-all;
}

.spinner {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #49596B;
  border-left: 2px solid #49596B;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  animation: spin 2s linear infinite;
  display: inline-block;
  position: relative;
  top: 5px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadButton {
  width: 100%;
}

.loadButton > label {
  cursor: pointer;
}

button > label {
  font-size: 0.98em;
}

label > div.loadingIndicator {
  left: 50%;
  transform: translate(-900%,-150%);
}

.h-center {
  left: 50%;
  transform: translate(-50%);
}

.note {
  display: flex;
}
.note > span{
  font: normal normal normal 14px/22px Roboto;
  color: #9D6FDF;
  text-transform: none !important;
}
.note > img{
  margin-right: 10px;
}

.option-icon{
  width: 20px;
  height: 20px;
  margin-right: 15px;
  margin-bottom: -5px;
}

li.react-tabs__tab{
  font: normal normal normal 14px/22px Roboto;
  padding: 0 30px 10px 30px;
}
li.react-tabs__tab--selected{

  background-color: transparent;
  border: none;
  border-radius: 0;
  color: #9D6FDF
}
li.react-tabs__tab:focus {
  box-shadow: none;
  border-color: none;
  outline: none;
}

li.react-tabs__tab:focus:after, li.react-tabs__tab--selected:after {
  content: "";
  position: absolute;
  height: 3px;
  left: 0px;
  right: 0px;
  bottom: -2px;
  background: #9D6FDF;
  border-radius: 50px;
}

.react-tabs__tab--disabled:after{
  right: 0;
  top: 3px;
  background-image: url('./images/icon-lock-gray.png');
}
ul.react-tabs__tab-list{
  margin-bottom: 20px;
  white-space: nowrap;
}

.fixed-headers {
  overflow: auto;
  height: 450px;
  overflow-x: hidden;
}
.fixed-headers thead th { position: sticky; top: 0; z-index: 1; background-color: var(--block-bg-color); }

.calc-clock span{
  padding-left: 15px;
  font: normal normal normal 16px/26px Arkhip;
  letter-spacing: 0px;
  color: #49596B;
}

.data-block hr {
  margin-top: 10px;
  border: 1px solid #FFFFFF;
}
.go-green-b{
  margin-top: 30px;
  display: flex;
}
.go-green-b > div:not(:first-child) {
  flex: 1 1 auto;
}
.go-green-b > div:first-child{
  width: 26px;
}
.go-green-b > div:last-child{
  text-align: end;
}
.go-green-b-info{
  color: #9D6FDF;
  font: normal normal 500 14px/22px Roboto;
}
.go-green-b-info > div {
  max-width: 650px;
}

@media only screen and (max-width: 1100px) {
  .navWrapper {
    position: absolute;
  }
  .go-green-b-info{
    padding-right: 40px;
  }
}

.floating-wallet{
  position: fixed;
  right: 25px;
  top: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #C8C8C8;
  line-height: 57px;
  text-align: center;
  cursor: pointer;
  z-index: 100001 !important;
  box-shadow: 0px 0px 6px #9d6fdf;
}

.floating-wallet.connected{
  background: transparent linear-gradient(130deg,#AE61E2,#28DBC6) 0 0 no-repeat padding-box;
}
.wallet-modal > .modal {
  right: 25px;
  top: 90px;
  left: initial;
  transform: none;
  box-shadow: 0px 0px 6px #9D6FDF;
}

.wallet-modal > .modal button {
  font-size: 16px;
}

.wallet-modal .wallet-modal-container{
  padding: 20px 30px;
  min-width: auto;
  width: 300px;
}

.wallet-modal-container label{
  margin-bottom: 15px;
}
.disconnet-wallet-btn{
  padding: 11px 19px 14px;
  width: 100%;
  margin-top: 15px;
}
.disconnet-wallet-btn img{
  margin-bottom: -3px;
  margin-left: 10px;
}
.wallet-info, .margin-top{
  margin-top: 15px;
}
.wallet-info img{
  vertical-align: middle;
  margin-right: 15px;
}
.wallet-info > div {
  margin-bottom: 20px;
}
.clipboard-available{
  cursor: pointer;
}

.offset-container{
  margin: 15px 0;
  padding: 15px 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #49596B33;
  border-radius: 8px;
}
.offset-header{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.offset-header label {
  padding: 0;
}
.offset-header .blockchain-icon{
  width: 35px;
}
.offset-header .blockchain{
  width: 215px;
  vertical-align: middle;
}
.offset-header .address{
  flex: 1 0 auto;
}
.offset-header > div {
  flex-shrink: 0;
}
.data-block label{
  width: 250px;
  display: inline-block;
}
.data-block > div > div {
  margin: 11px 0;
}
.data-block a {
  text-decoration: none;
  color: #6B9ED4;
  font-size: 16px;
}
.clipboard-icon{
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-image: url('./images/icon-copy.png');
  display: inline-block;
  vertical-align: middle;
  align-self: center;
}
.clipboard-icon.clipboard-available:hover{
  background-image: url('./images/icon-copy-hover.png');
}
.offset-container .clipboard-icon{
  width: 20px;
  height: 20px;
  margin-right: 15px;
  background-image: url('./images/icon-copy-sm.png');
  align-self: center;
}
.offset-container .clipboard-icon:hover{
  background-image: url('./images/icon-copy-sm-hover.png');
}
.offset-container img {
  vertical-align: middle;
  margin-bottom: 3px;
}
.wallet-modal .modal {
  animation:modalSlide 0.26s ease-in forwards;
}


@keyframes modalSlide {
  from {
    transform: translate(150px, -60%) scale(0, 0);
    opacity: 0;
  }

  to {
    transform: translate(0, 0) scale(100%, 100%);
    opacity: 1;
  }
}

.modal:focus {outline:0;}
.clock-icon{
  width: 20px;
  height: 20px;
  vertical-align: sub;
}

.dapp-container > div > .offset-container:first-child, .dapp-container >  .offset-container:first-child {
  margin-top: 0;
}

.react-tiny-popover-container{
  margin: 10px;
  padding: 10px 15px;
  border-radius: var(--border-radius);
  height: auto;
  z-index: 100001;
}

.account-empty{
  margin-top: 15px;
  color: red;
}

.content-container-dark{
  background: #C7CCD1 0% 0% no-repeat padding-box;
}
.content-container-accent{
  background: #7D8DD8 0% 0% no-repeat padding-box;
  color: #FFFFFF;
}
.note > img{
  margin-right: 10px;
  vertical-align: text-top;
  font-weight: medium 500;
}
.reason-for-greening-container{
  display:  flex;
}
.data-block .reason-for-greening-container > label{
  min-width: 250px;
}

.copy-icon {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding-left: 30px;
}

.copy-icon::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 50%;
  width: 2px;
  background-color: #37CCC8;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

.rotate{
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.copy-icon::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 35%;
  background-color: #37CCC8;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

.empty {
  color: rgb(109, 109, 109);
  cursor: default !important;
}

.flexDisplay {
  display: flex;
}

.noShrink {
  flex-shrink: 0;
}

.ellipsableData {
  width: calc(100vw - 820px);
  min-width: 255px;
}

.ellipsableDataHeader {
  width: calc(100vw - 910px);
  min-width: 210px;
}

@media only screen and (min-width: 1280px) {
  .menu-full-logo-img {
    display: block;
  }
  .menu-logo-img {
    display: none;
  }
}
@media only screen and (max-width: 1280px) {
  nav ul li a div.menuName {
    display: none;
  }
  nav ul li:hover a div.menuName{
    display: block;
    color: #7D8DD8;
  }
  nav ul li:hover {
    width: max-content;
    padding-right: 20px;
    background: var(--bg-color);
    box-shadow: 0px 3px 4px #49596B33;
    border-radius: 101px;
  }
  nav ul li:hover a.selected::before {
    box-shadow: none;
  }
  nav ul li:hover a.selected::after {
    box-shadow: none;
  }

  nav {
    width: 80px;
  }
  .menu-full-logo-img {
    display: none;
  }
  .menu-logo-img {
    display: block;
  }
  .content-container {
    left: 110px;
    width: calc(100vw - 290px);
  }
  .ellipsableData {
    width: calc(100vw - 635px);
  }
  .ellipsableDataHeader {
    width: calc(100vw - 725px);
  }
}

.content-container-inner, .content-container-modal, .offset-container, .data-block {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.content-container-inner, .content-container-modal, .offset-container, .react-tabs, .container-inner {
  min-width: 600px;
}

.data-block {
  min-width: 540px;
}

.container-inner {
  margin-bottom: 30px;
  display: flex;
}

.right-align-items {
  text-align: right;
  flex: 1 1 auto;
}

.tt-none {
  text-transform: none;
}

.font-16-20-roboto {
  font: normal normal normal 16px/20px Roboto !important;
}

.checkbuttonContainer input {
  display: inline;
  height: 15px;
  width: 15px;
  margin-top:4px;
  vertical-align: top;
}

.checkbuttonContainer label {
  display: inline-block;
  text-transform: initial;
  width: 90%;
  padding:0px;
  margin-left: 10px;
}

.checkBoxText{
  font-family: Roboto-regular;
  font-weight: regular;
  font-size: 16px;
}

.noDisabledLock:disabled::after {
  content: none;
}

.solflare-wallet-adapter-iframe iframe {
  z-index: 100002 !important;
}